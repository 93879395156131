import { LS_KEY_BROSWER, LS_USER_INFO } from 'utils/constants/localStorage';
import jwtDecode from 'jwt-decode';
import * as apiRoutes from './routesApi';
import axios from 'axios';

export const accessToken = () => {
  const name = 'scTechzen_auth=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookie = decodedCookie.split(';');
  for (let i = 0; i < cookie.length; i++) {
    let cookie_str = cookie[i];
    while (cookie_str.charAt(0) === ' ') {
      cookie_str = cookie_str.substring(1);
    }
    if (cookie_str.indexOf(name) === 0) {
      const accessToken = cookie_str.substring(name.length, cookie_str.length);
      if (accessToken) {
        return `Bearer ${accessToken}`;
      }
      return '';
    }
  }
};

export const setAccessToken = (accessToken: string) => {
  const date = new Date();
  date.setTime(date.getTime() + 60 * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `scTechzen_auth=${accessToken};${expires};path=/;${
    window.location.protocol === 'https' ? 'secure;' : ''
  }`;
};

export const isAuth = () => {
  return Boolean(accessToken());
};

export const logOut = async (isCallApi?: boolean) => {
  const access_token = accessToken();
  const config = {
    headers: {
      Authorization: access_token,
      'browser-key': browserKey(),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    },
  };
  document.cookie = 'scTechzen_auth=; Max-Age=0; path=/;';
  localStorage.removeItem(LS_USER_INFO);
  if (!isCallApi) {
    try {
      const response = await axios.get('https://httpbin.org/ip');
      if (response.data.origin) {
        const ip = response.data.origin;
        config.headers['public-ip'] = ip;
        config.headers['key-device'] = getDeviceKey();
      } else {
        const response = await axios.get('https://api.ipify.org?format=json');
        const ip = response.data.ip;
        config.headers['public-ip'] = ip;
        config.headers['key-device'] = getDeviceKey();
      }
    } catch (error) {
      console.error('Error getting IP', error);
    }
    try {
      axios.get(`${process.env.REACT_APP_HOST}${apiRoutes.LOG_OUT}`, config);
    } catch (error) {
      console.error('Error Log Out', error);
    }
  }
};

export const browserKey = () => {
  const keyBroswer = localStorage.getItem(LS_KEY_BROSWER);
  if (!keyBroswer) return '';
  return keyBroswer;
};

export const getUserInfoFromAccessToken = () => {
  const token = accessToken();
  if (!token) return null;
  const user = jwtDecode(token.substring(7));
  return user;
};

export const getDeviceKey = () => {
  const name = 'DeviceKey';
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }
};

export const setDeviceKey = (deviceKey: string) => {
  const date = new Date();
  date.setTime(date.getTime() + 365 * 3 * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `DeviceKey=${deviceKey};${expires};path=/;${
    window.location.protocol === 'https' ? 'secure;' : ''
  }`;
};

// export const getLoginKey = () => {
//   const name = 'LoginKey';
//   const cookies = document.cookie.split(';');
//   for (let i = 0; i < cookies.length; i++) {
//     const cookie = cookies[i].trim();
//     const [cookieName, cookieValue] = cookie.split('=');
//     if (cookieName === name) {
//       return cookieValue;
//     }
//   }
// };

// export const setLoginKey = (loginKey: string) => {
//   const date = new Date();
//   date.setTime(date.getTime() + 365 * 3 * 24 * 60 * 60 * 1000);
//   const expires = `expires=${date.toUTCString()}`;
//   document.cookie = `LoginKey=${loginKey};${expires};path=/;${
//     window.location.protocol === 'https' ? 'secure;' : ''
//   }`;
// };

export const setLoginKeyArray = (newLoginKey: string) => {
  const name = 'LoginKey';
  const cookies = document.cookie.split(';');
  let existingArray = [];

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      existingArray = JSON.parse(cookieValue);
      break;
    }
  }

  existingArray.push(newLoginKey);

  const date = new Date();
  date.setTime(date.getTime() + 365 * 3 * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  const loginKeyArrayStr = JSON.stringify(existingArray);
  document.cookie = `LoginKey=${loginKeyArrayStr};${expires};path=/;${
    window.location.protocol === 'https' ? 'secure;' : ''
  }`;
};

export const getLoginKeyArray = () => {
  const name = 'LoginKey';
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return JSON.parse(cookieValue);
    }
  }
  return null;
};
