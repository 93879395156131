import '@livekit/react-components/dist/index.css';
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { CSpinner } from '@coreui/react-pro';
import './scss/style.scss';
import './utils/lang/index';
import { authHelper, urlRoutes } from 'utils/helpers';
import Modal from 'components/common/modal/Modal';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'utils/hooks';
import { CLEAR_MODAL, SET_LOADER, SET_MODAL } from 'redux/actions/type';
import { useTranslation } from 'react-i18next';
import { INFO_ICON, WARNING_ICON } from 'utils/constants/images';
import Button from 'components/common/button/Button';
import { ToastContainer } from 'react-toastify';
import showToast from 'components/common/toast/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { io } from 'socket.io-client';
import { LS_KEY_BROSWER, LS_USER_INFO } from 'utils/constants/localStorage';
import ManyLoginAttempts from 'views/notFoundAndPermission/ManyLoginAttemps';
import { fetchAllowDeviceKey, fetchApiMain, fetchRefreshToken } from 'redux/actions';
import { Env } from './enums';
import { setAccessToken, setDeviceKey } from 'utils/helpers/auth';
import { ROLE_ACCOUNT } from 'utils/constants/enums';
import axios from 'axios';
import 'assets/css/AiHelper.css';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/login/Login'));
const RoomPage = React.lazy(() => import('./views/roomPage/RoomPage'));
const PreJoinPage = React.lazy(() => import('./views/preJoin/PreJoinPage'));
const OTPForm = React.lazy(() => import('./views/otpform/OTPForm'));
const NotFound = React.lazy(() => import('./views/notFoundAndPermission/NotFound'));
const NotPermission = React.lazy(() => import('./views/notFoundAndPermission/NotPermission'));
const NotAllowed = React.lazy(() => import('./views/notFoundAndPermission/NotAllowed'));
const NotAllowedInSystem = React.lazy(
  () => import('./views/notFoundAndPermission/NotAllowdInSystem'),
);
const FirstEnterPassWord = React.lazy(
  () => import('./views/firstEnterPassword/FirstEnterPassword'),
);
const ChangePassword = React.lazy(() => import('./views/changePassword/ChangePassword'));
const ForgotPassword = React.lazy(() => import('./views/forgotPassword/ForgotPassword'));

const App = () => {
  const stateModal = useSelector((states: any) => states.modalState);
  const currentLocation = useLocation().pathname;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<any>({
    data: null,
    icon: null,
    isOpen: false,
    action: null,
  });

  const [openModalChangeRole, setOpenModalChangeRole] = useState<any>({
    data: null,
    isOpen: false,
    roleName: null,
  });

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== Env.LOCAL) {
      console.log = () => {};
      console.warn = () => {};
      console.debug = () => {};
    }
  }, []);

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_HOST}`);
    newSocket.on('checkLogout', (data) => {
      const { userId, keyBroswer } = data;
      const keyLocal = localStorage.getItem(LS_KEY_BROSWER);
      const userInfo = JSON.parse(localStorage.getItem(LS_USER_INFO) ?? '');
      if (keyLocal && userInfo?.id === userId && keyLocal !== keyBroswer) {
        setTimeout(() => {
          authHelper.logOut();
        }, 500);
        setOpenModal({
          data: t('login.other_login'),
          icon: WARNING_ICON,
          isOpen: true,
          action: 'checkLogout',
        });
      }
    });

    newSocket.on('checkActive', (data) => {
      const { userId } = data;
      const userInfo = authHelper.getUserInfoFromAccessToken();
      if (userInfo && userInfo?.['id'] === userId) {
        setTimeout(() => {
          authHelper.logOut();
          navigate(urlRoutes.LOGIN);
          setOpenModal({
            data: t('login.username_are_inactived'),
            icon: WARNING_ICON,
            isOpen: true,
            action: 'checkActive',
          });
        }, 500);
      }
    });

    newSocket.on('checkAddOrRemoveAdminRoom', (data) => {
      const { userId, action, roomName } = data;
      const userInfo = authHelper.getUserInfoFromAccessToken();

      if (userInfo && userInfo?.['id'] === userId) {
        let data;
        if (action === 'Add') {
          data = `${t('base.add_admin_room')}: ${roomName}`;
        } else {
          data = `${t('base.remove_admin_room')}: ${roomName}`;
        }
        setOpenModal({
          data: data,
          icon: INFO_ICON,
          isOpen: true,
          action: 'checkAddOrRemoveAdminRoom',
        });
      }
    });

    newSocket.on('sendKeyDeviceToClient', (data) => {
      const { deviceKeyId, username } = data;
      const userInfo = authHelper.getUserInfoFromAccessToken();

      if (userInfo && userInfo?.['username'] === username) {
        dispatch(
          fetchAllowDeviceKey({
            isLoad: true,
            params: {
              id: deviceKeyId,
            },
            callBack: (result: any) => {
              if (result.message !== 'device_address_not_found') {
                setDeviceKey(result);
              }
            },
          }),
        );
      }
    });

    newSocket.on('refreshTokenToClient', (data, role) => {
      let roleName;
      switch (role) {
        case ROLE_ACCOUNT.ADMIN:
          roleName = 'SUPER ADMIN';
          break;
        case ROLE_ACCOUNT.USER:
          roleName = 'USER';
          break;
        default:
          break;
      }

      const userInfo = authHelper.getUserInfoFromAccessToken();
      if (data === userInfo?.['id']) {
        setOpenModalChangeRole({
          data: data,
          isOpen: true,
          roleName: roleName,
        });
      }
    });

    newSocket.on('updateIPDone', async (publicIP) => {
      if (currentLocation == '/not-allowed-in-system') {
        const response = await axios.get('https://httpbin.org/ip');
        if (response.data.origin) {
          if (publicIP === response.data.origin) {
            navigate('/login');
          }
        } else {
          const response = await axios.get('https://api.ipify.org?format=json');
          if (publicIP === response.data.ip) {
            navigate('/login');
          }
        }
      }
    });

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (stateModal?.isShowToast !== '') {
      setTimeout(() => {
        if (authHelper.isAuth()) {
          showToast(`${t('base.session_expired')}\n${t('base.please_login_again')}`, 'error', 3000);
        }
      }, 1000);
      setTimeout(() => {
        navigate(urlRoutes.LOGIN);
        dispatch({ type: SET_MODAL, isShowToast: '' });
        authHelper.logOut();
        dispatch({ type: CLEAR_MODAL });
      }, 3500);
    }
  }, [stateModal?.isShowToast]);

  const handleCancelModal = () => {
    if (openModal?.action === 'checkAddOrRemoveAdminRoom' && currentLocation !== '/room') {
      window.location.reload();
    } else {
      if (openModal?.action === 'checkLogout') {
        navigate(urlRoutes.LOGIN);
      }
    }
    setOpenModal({
      data: null,
      isOpen: false,
    });
  };

  const getApiMain = async () => {
    dispatch(
      fetchApiMain({
        isLoad: true,
        params: {},
        callBack: (result: any) => {
          if (result) {
            //Call Api for check IP in system
          }
        },
      }),
    );
  };

  useEffect(() => {
    if (currentLocation !== urlRoutes.NOT_ALLOWED_IN_SYSTEM) {
      getApiMain();
    }
    dispatch({
      type: SET_LOADER,
      data: false,
    });
  }, []);

  const handleRfToken = (data) => {
    dispatch(
      fetchRefreshToken({
        isLoad: true,
        params: {},
        callBack: (result: any) => {
          if (result) {
            if (result?.message === 'refresh_token_success') {
              setAccessToken(result?.data?.access_token);
              localStorage.setItem(LS_USER_INFO, JSON.stringify(result?.data?.data));
              window.location.reload();
              setOpenModalChangeRole({
                data: null,
                isOpen: false,
                roleName: null,
              });
            }
          }
        },
      }),
    );
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          <Route path={urlRoutes.ROOM} element={<RoomPage />} />
          <Route path="/" element={<PreJoinPage />} />
          <Route path="*" element={<DefaultLayout />} />
          <Route path={urlRoutes.LOGIN} element={<Login />} />
          <Route path={urlRoutes.VERIFY_OTP} element={<OTPForm />} />
          <Route path={urlRoutes.RESET_PASSWORD} element={<FirstEnterPassWord />} />
          <Route path={urlRoutes.NOT_FOUND} element={<NotFound />} />
          <Route path={urlRoutes.NOT_PERMISSION} element={<NotPermission />} />
          <Route path={urlRoutes.NOT_ALLOWED} element={<NotAllowed />} />
          <Route path={urlRoutes.CHANGE_PASSWORD} element={<ChangePassword />} />
          <Route path={urlRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={urlRoutes.MANY_LOGIN_ATTEMPTS} element={<ManyLoginAttempts />} />
          <Route path={urlRoutes.NOT_ALLOWED_IN_SYSTEM} element={<NotAllowedInSystem />} />
        </Routes>
      </Suspense>
      <Modal
        content={
          <div className="px-4">
            <div className="d-flex gap-4 align-items-center">
              <img src={openModal?.icon} alt="logo" height={40} style={{ cursor: 'pointer' }} />
              <div>
                <div>{t(openModal?.data)}</div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <Button
                style={{ width: '100px' }}
                onClick={handleCancelModal}
                label={t('base.ok')}
                className="btn-primary"
              />
            </div>
          </div>
        }
        visible={openModal?.isOpen}
        onClose={handleCancelModal}
        handleOK={() => {}}
        alignment={'center'}
        hiddenIconX={true}
        marginTopContent={true}
      />

      <Modal
        content={
          <div className="px-4">
            <div className="d-flex gap-4 align-items-center gap-2">
              <img src={INFO_ICON} alt="logo" height={40} style={{ cursor: 'pointer' }} />
              <div>
                <div>
                  {t('account_management.change_role', {
                    item_role: openModalChangeRole?.roleName,
                  })}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <Button
                style={{ width: '100px' }}
                onClick={() => handleRfToken(openModalChangeRole?.data)}
                label={t('base.ok')}
                className="btn-primary"
              />
            </div>
          </div>
        }
        visible={openModalChangeRole?.isOpen}
        handleOK={() => {}}
        alignment={'center'}
        hiddenIconX={true}
        marginTopContent={true}
      />
    </>
  );
};

export default App;
