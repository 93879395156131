const LOGIN = '/auth/login';
const VERIFY_OTP = '/auth/verify-otp';
const VERIFY_2FA = '/auth/enable-2fa';
const CHANGE_PASSWORD = '/auth/change-password';
const GROUP = '/api/v1/group';
const TEAM = '/api/v1/team';
const CHANGE_STATUS = 'change-status';
const LIST_ACCOUNT = '/auth/users';
const ADD_ACCOUNT = '/auth/register';

const RESET_PASSWORD = '/auth/reset-password';
const ROOM = '/rooms';
const UPDATE_MEMBER_OF_ROOM = '/rooms/add-users';
const CHECK_SET_PASSWORDKEY = '/auth/check-setpassword-key';
const LIST_ROOM_BY_ACCOUNT = '/rooms/account';
const CREATE_TOKEN_ROOM = '/rooms/create-token-room';
const CHECK_USER_EXIST_IN_ROOM = '/rooms/is-check-users-exist-in-room';
const MESSAGE_BY_ROOM = '/messages';
const CHECK_PUBLIC_IP_ALLOWED = '/auth/check-public-ip-allowed';
const SET_ACTIVE = 'auth/set-active';
const SET_BLOCK_LOGIN = 'auth/set-block-login';
const LANGUAGE = '/language';
const SUBSCRIPTION = '/notification/subscription';
const GET_LIST_PUBLIC_IP_BY_ACCOUNT = 'public-ip-allowed';
const LIST_ACCOUNT_FOR_UPDATE_TO_ROOM = 'auth/users-for-update-to-room';
const LOG_OUT = '/auth/logout';
const ADD_OR_REMOVE_IP = '/public-ip-allowed/add-or-remove-public-ip';
const ADD_NEW_PUBLIC_IP = '/public-ip-allowed/add-new-public-ip-in-room';
const GET_LIST_ID_ADMIN_IN_ROOM = '/rooms/list-id-admin-in-room';
const ADD_ID_ADMIN_IN_ROOM = '/rooms/apply-admin-room';
const SENT_EMAIL_FORGOT_PASSPORT = 'auth/sent-mail-forgot-password';
const CHECK_ADMIN_ROOM = 'auth/check-admin-room';
const FIND_ALL_PUBLIC_IP = '/public-ip-allowed/find-all';
const SET_ALLOWED_PUBLIC_IP = '/public-ip-allowed/set-allowed';
const ADD_NEW_PUBLIC_IP_ADRESS = '/public-ip-allowed/add-new-public-ip';
const API_MAIN_HELLO = '/';
const SET_BLOCK_OTP = 'auth/set-block-otp';
const SUGGEST_MESSAGE = '/messages/suggest';
const CHECK_IP_IN_ROOM = '/public-ip-allowed/check-ip-in-room';
const GET_PROFILE = '/auth/get-profile';
const UPDATE_PROFILE = '/auth/update-profile';
const VOCABULARY_IN_ROOM = '/vocabulary';
const VOCABULARY_DELETED = '/vocabulary/deleted';
const VOCABULARY_UPDATE = '/vocabulary/update';
const CHECK_ADMIN_ROOM_BY_ID = 'auth/check-admin-room-by-room-id';
const SET_ALLOW_READ_IMAGE = '/rooms/allow-read-image';
const GET_LIST_IP_FOR_UPDATE = 'public-ip-allowed/get-ip-for-update';
const CHANGE_STATUS_SYSTEM_IP = '/system-ip-status/update';
const SYSTEM_IP = '/system-ip-status';
const GET_LIST_IP_IN_ROOM = '/public-ip-allowed/list-ip-add-system';
const ADD_OR_REMOVE_IP_IN_SYSTEM = '/public-ip-allowed/add-or-remove-public-ip-in-system';
const DEVICE_ADDRESS = '/device-address';
const TRANSLATE_IMAGE = '/chat-image';
const SEND_DEVICE_ADDRESS = '/device-address/send-device-address';
const ACCEPT_DEVICE_ADDRESS = '/device-address/accept-device-address';
const SET_STATUS_DEVICE_ADDRESS = '/device-address/set-status-device';
const ALLOW_DEVICE_ADDRESS = '/device-address/allow-key-device';
const SET_ALLOWED_LIST_PUBLIC_IP = '/public-ip-allowed/set-allowed-list-publicip';
const UPDATE_ROLE = 'auth/update-role';
const REFRESH_TOKEN = 'auth/refresh-token';
const API_CALL_HISTORY = '/api-call-history';
const KEY_LOGIN = '/history-key-login';
const GET_ROOM_BY_ID = '/rooms/get-room-id';
const CREATE_DATA_EXAMPLE = '/ai-helper/create-data-example';
const AI_HELPER_REVIEW_CODE = '/ai-helper/review-code';
const BAD_WORD = '/bad-word';
const AI_HELPER = '/ai-helper';
const ALLOW_AI_HELPER = '/rooms/allow-ai-helper';
const AI_HELPER_UNIT_TEST = '/ai-helper/unit-test';
const ALLOW_TRANSLATE_FILE = '/rooms/allow-translate-file';
const SET_RE_AUTHEN = 'auth/set-reauthen';
const TRANSLATE_FILE_EXCEL = '/rooms/translate-file-excel';
const TRANSLATE_FILE_PDF = '/rooms/translate-file-pdf';
const KEEP_KPT = '/keep-kpt';
const PROBLEM_KPT = '/problem-kpt';
const TRY_KPT = '/try-kpt';
const SPRINT_KPT = '/sprint';
const CATEGORIES_KPT = '/categories-kpt';
const DELETE_CATEGORIES_KPT = '/categories-kpt/delete';
const KEEP_CATEGORIES = '/keep-kpt/add-categories';
const PROBLEM_CATEGORIES = '/problem-kpt/add-categories';
const TRY_CATEGORIES = '/try-kpt/add-categories';
const DELETE_KEEP_KPT = '/keep-kpt/delete';
const DELETE_PROBLEM_KPT = '/problem-kpt/delete';
const DELETE_TRY_KPT = '/try-kpt/delete';
const SPRINT_KPT_STATUS = '/sprint/status';
const KEEP_KPT_BY_VOTED = '/keep-kpt/by-voted';
const PROBLEM_KPT_BY_VOTED = '/problem-kpt/by-voted';
const TRY_KPT_BY_VOTED = '/try-kpt/by-voted';
const VOTE_KEEP_KPT = '/keep-kpt/vote';
const VOTE_TRY_KPT = '/try-kpt/vote';
const VOTE_PROBLEM_KPT = '/problem-kpt/vote';
const COMMENT_KPT = '/comment-kpt';
const DELETE_COMMENT_KPT = '/comment-kpt/delete';
const SPRINT_KPT_TIME = '/sprint/kpt-time';
const ADD_PROBLEM_TO_TRY = '/try-kpt/add-problem';
const KPT_IMAGE = '/images-in-sprint';
const DELETE_KPT_IMAGE = '/images-in-sprint/delete';
const USER_POSITION_KPT = '/user-position-kpt';
export {
  LOGIN,
  VERIFY_OTP,
  CHANGE_PASSWORD,
  GROUP,
  TEAM,
  CHANGE_STATUS,
  LIST_ACCOUNT,
  ADD_ACCOUNT,
  RESET_PASSWORD,
  ROOM,
  UPDATE_MEMBER_OF_ROOM,
  CHECK_SET_PASSWORDKEY,
  LIST_ROOM_BY_ACCOUNT,
  CREATE_TOKEN_ROOM,
  CHECK_USER_EXIST_IN_ROOM,
  MESSAGE_BY_ROOM,
  LANGUAGE,
  CHECK_PUBLIC_IP_ALLOWED,
  SET_ACTIVE,
  SUBSCRIPTION,
  LIST_ACCOUNT_FOR_UPDATE_TO_ROOM,
  GET_LIST_PUBLIC_IP_BY_ACCOUNT,
  LOG_OUT,
  ADD_OR_REMOVE_IP,
  ADD_NEW_PUBLIC_IP,
  GET_LIST_ID_ADMIN_IN_ROOM,
  ADD_ID_ADMIN_IN_ROOM,
  SENT_EMAIL_FORGOT_PASSPORT,
  CHECK_ADMIN_ROOM,
  SET_BLOCK_LOGIN,
  FIND_ALL_PUBLIC_IP,
  SET_ALLOWED_PUBLIC_IP,
  ADD_NEW_PUBLIC_IP_ADRESS,
  API_MAIN_HELLO,
  SET_BLOCK_OTP,
  SUGGEST_MESSAGE,
  CHECK_IP_IN_ROOM,
  GET_PROFILE,
  UPDATE_PROFILE,
  VOCABULARY_IN_ROOM,
  VOCABULARY_DELETED,
  VOCABULARY_UPDATE,
  CHECK_ADMIN_ROOM_BY_ID,
  SET_ALLOW_READ_IMAGE,
  GET_LIST_IP_FOR_UPDATE,
  CHANGE_STATUS_SYSTEM_IP,
  SYSTEM_IP,
  GET_LIST_IP_IN_ROOM,
  ADD_OR_REMOVE_IP_IN_SYSTEM,
  DEVICE_ADDRESS,
  TRANSLATE_IMAGE,
  SEND_DEVICE_ADDRESS,
  ACCEPT_DEVICE_ADDRESS,
  SET_STATUS_DEVICE_ADDRESS,
  ALLOW_DEVICE_ADDRESS,
  SET_ALLOWED_LIST_PUBLIC_IP,
  UPDATE_ROLE,
  REFRESH_TOKEN,
  API_CALL_HISTORY,
  KEY_LOGIN,
  GET_ROOM_BY_ID,
  CREATE_DATA_EXAMPLE,
  AI_HELPER_REVIEW_CODE,
  BAD_WORD,
  AI_HELPER,
  ALLOW_AI_HELPER,
  VERIFY_2FA,
  AI_HELPER_UNIT_TEST,
  ALLOW_TRANSLATE_FILE,
  SET_RE_AUTHEN,
  TRANSLATE_FILE_EXCEL,
  TRANSLATE_FILE_PDF,
  KEEP_KPT,
  PROBLEM_KPT,
  TRY_KPT,
  SPRINT_KPT,
  CATEGORIES_KPT,
  KEEP_CATEGORIES,
  PROBLEM_CATEGORIES,
  TRY_CATEGORIES,
  DELETE_KEEP_KPT,
  DELETE_PROBLEM_KPT,
  DELETE_TRY_KPT,
  SPRINT_KPT_STATUS,
  KEEP_KPT_BY_VOTED,
  PROBLEM_KPT_BY_VOTED,
  TRY_KPT_BY_VOTED,
  VOTE_KEEP_KPT,
  VOTE_TRY_KPT,
  VOTE_PROBLEM_KPT,
  COMMENT_KPT,
  DELETE_COMMENT_KPT,
  SPRINT_KPT_TIME,
  ADD_PROBLEM_TO_TRY,
  KPT_IMAGE,
  DELETE_KPT_IMAGE,
  DELETE_CATEGORIES_KPT,
  USER_POSITION_KPT,
};
