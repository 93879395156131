import { ROLE_ACCOUNT } from 'utils/constants/enums';

export const enum LANGUAGE {
  JAPAN = 'jp',
  ENGLISH = 'en',
}

export const enum LANGUAGE_TRANSLATE {
  VN = 'vi',
  JP = 'ja',
}

export const getNameofRole = (role: string) => {
  let name = 'SUPER ADMIN';
  switch (role) {
    case ROLE_ACCOUNT.ADMIN:
      name = 'SUPER ADMIN';
      break;
    case ROLE_ACCOUNT.USER:
      name = 'USER';
      break;
    default:
      name = 'SUPER ADMIN';
      break;
  }
  return name;
};

export const getArrayLanguageTranslate = (language: string) => {
  let arrayLanguage = [];
  switch (language) {
    case 'vi':
      arrayLanguage = [LANGUAGE_TRANSLATE.JP];
      break;
    case 'ja':
      arrayLanguage = [LANGUAGE_TRANSLATE.VN];
      break;
    case 'zh':
      arrayLanguage = [LANGUAGE_TRANSLATE.VN];
      break;
    default:
      break;
  }
  return arrayLanguage;
};

export const enum TYPE_DATA_EXAMPLE {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  OBJECT = 'Object',
}

export const enum OPTIONS_DATA_EXAMPLE {
  DESCRIPTION_OBJECT = 'Description data',
  CREATE_OBJECT = 'Create new data',
}

export const enum UNIT_TEST_LANGUAGE {
  PYTHON = 'Python',
  JAVA = 'Java',
  JS = 'JS',
}

export const enum UNIT_TEST_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTION = 'OPTION',
}

export const enum KPT_IN_ROOM {
  KPT_BOARD = 'KPT Board',
  KPT_MANAGEMENT = 'Try Management',
}

export const enum KPT_OPTION {
  K = 'K',
  P = 'P',
  T = 'T',
  I = 'I',
}

export const enum OPTION_TRY_STATUS_CLOSED {
  IS_HIDE_STATUS_CLOSED = 1,
  IS_SHOW_STATUS_CLOSED = 0,
}
