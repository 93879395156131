import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { store } from 'redux/store/store';
import { apiHelper, authHelper } from 'utils/helpers';
import { SET_LOADER } from './type';

const setLoader = (data: boolean = false) => {
  return {
    type: SET_LOADER,
    data,
  };
};

export const fetchVerifyOTP = createAsyncThunk(
  'fetchVerifyOTP',
  async (data: IFetchVerifyOTPDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchVerifyOTP(data.data);
      if (res.status === 200) {
        authHelper.setAccessToken(res.data.access_token ?? '');
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchVerify2fa = createAsyncThunk(
  'fetchVerify2fa',
  async (data: IFetchVerify2faDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchVerify2fa(data.data);
      if (res.status === 201) {
        authHelper.setAccessToken(res.data.access_token ?? '');
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchLogin = createAsyncThunk('fetchLogin', async (data: IFetchLoginDataAPI) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }

  try {
    const res = await apiHelper.fetchApiLogin(data.data);
    if (res.status === 200) {
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchResetPassword = createAsyncThunk(
  'fetchResetPassword',
  async (data: IFetchResetPasswordDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiResetPassword(data.data);

      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchChangePassword = createAsyncThunk(
  'fetchChangePassword',
  async (data: IFetchChangePasswordDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiChangePassword(data.data);
      if (res.status === 200) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCreateRoom = createAsyncThunk(
  'fetchCreateRoom',
  async (data: IFetchCreateRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchCreateRoom(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListRoom = createAsyncThunk(
  'fetchGetListRoom',
  async (data: IFetchListRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchGetListRoom(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddAcount = createAsyncThunk(
  'fetchAddAcount',
  async (data: IFetchAddAccountDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiAddAccount(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchListAccount = createAsyncThunk(
  'fetchListAccount',
  async (data: IFetchListAccountDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiListAccount(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateMemberInRoom = createAsyncThunk(
  'fetchUpdateMemberInRoom',
  async (data: IFetchUpdateMemberInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchUpdateMemberInRoom(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCheckSetPasswordKey = createAsyncThunk(
  'fetchCheckSetPasswordKey',
  async (data: IFetchCheckSetPasswordKeyDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiCheckSetPasswordKey(data.data);

      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListRoomByAccount = createAsyncThunk(
  'fetchGetListRoomByAccount',
  async (data: IFetchListRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListRoomByAccount(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCreateTokenRoom = createAsyncThunk(
  'fetchCreateTokenRoom',
  async (data: IFetchCreateTokenRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiCreateTokenRoom(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCheckUserExistInRoom = createAsyncThunk(
  'fetchCheckUserExistInRoom',
  async (data: IFetchCheckUserExistInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchCheckUserExistInRoom(data.data);
      //if (res.status === 201) {
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
      //}
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchDataChatInRoom = createAsyncThunk(
  'fetchDataChatInRoom',
  async (data: IFetchDataChatInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchDataChatInRoom(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchTranslateImage = createAsyncThunk(
  'fetchTranslateImage',
  async (data: IFetchTranslateImageAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchTranslateImage(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchListLanguage = createAsyncThunk(
  'fetchListLanguage',
  async (data: IFetchLanguageDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchListLanguage(data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCheckPublicIpAllowed = createAsyncThunk(
  'fetchCheckPublicIpAllowed',
  async (data: IFetchPublicIpAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchCheckPublicIpAllowed(data.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSubcriptionToServer = createAsyncThunk(
  'fetchSubcriptionToServer',
  async (data: IFetchNotificationAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSubcriptionToServer(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSetActive = createAsyncThunk(
  'fetchSetActive',
  async (data: IFetchSetActiveAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSetActive(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSetReAuthen = createAsyncThunk(
  'fetchSetReAuthen',
  async (data: IFetchSetReAuthenAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSetReAuthen(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSetBlockLogin = createAsyncThunk(
  'fetchSetBlockLogin',
  async (data: IFetchSetBlockLoginAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSetBockLogin(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchListAccountForUpdateToRoom = createAsyncThunk(
  'fetchListAccountForUpdateToRoom',
  async (data: IFetchListAccountDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchListAccountForUpdateToRoom(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListPublicIpByUser = createAsyncThunk(
  'fetchGetListPublicIpByUser',
  async (data: IFetchListRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchGetListPublicIpByUser(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSendEmailForgotPassword = createAsyncThunk(
  'fetchChangePassword',
  async (data: IFetchForgotPasswordEmailAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiSendEmailForgotPassword(data.data);
      if (res.status === 200) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);
export const fetchUpdatePublicIPInRoom = createAsyncThunk(
  'fetchUpdatePublicIPInRoom',
  async (data: IFetchUpdateIPInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchUpdatePublicIPInRoom(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddNewPublicIPInRoom = createAsyncThunk(
  'fetchAddNewPublicIPInRoom',
  async (data: IFetchAddNewIPInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchAddNewPublicIPInRoom(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchListListIdAdminInRoom = createAsyncThunk(
  'fetchListListIdAdminInRoom',
  async (data: IFetchGetListIpAdminRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchListIdAdminInRoom(data);

      if (res.status === 200) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddAdminToRoom = createAsyncThunk(
  'fetchAddAdminToRoom',
  async (data: IFetchAddAdminToRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchAddAdminToRoom(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCheckIsAdminRoom = createAsyncThunk(
  'fetchCheckIsAdminRoom',
  async (data: any) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchCheckIsAdminRoom();
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchListPubLicIP = createAsyncThunk(
  'fetchListPubLicIP',
  async (data: IFetchGetPublicIpAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchListPubLicIP(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSetAllowed = createAsyncThunk(
  'fetchSetAllowed',
  async (data: IFetchSetAllowedPublicIpAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSetAllowed(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddNewPublicIP = createAsyncThunk(
  'fetchAddNewPublicIP',
  async (data: IFetchAddNewPublicIpAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchAddNewPublicIP(data?.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchApiMain = createAsyncThunk('fetchApiMain', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }

  try {
    const res = await apiHelper.fetchApiMain();
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchSetBlockOTP = createAsyncThunk(
  'fetchSetBlockOTP',
  async (data: IFetchSetBlockOTPAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSetBlockOTP(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const suggestMessage = createAsyncThunk(
  'fetchSuggestMessage',
  async (data: ISuggestMessageAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fechSuggestMessage(data?.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCheckIpInRoom = createAsyncThunk(
  'fetchCheckIpInRoom',
  async (data: IDataIPInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchCheckIpInRoom(data?.params);

      if (res.status === 200) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);
export const fetchGetProfile = createAsyncThunk(
  'fetchGetProfile',
  async (data: IFetchListAccountDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchGetProfile(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateProfile = createAsyncThunk(
  'fetchUpdateProfile',
  async (data: IDataProfileInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchUpdateProfile(data?.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListVocabularyInRoom = createAsyncThunk(
  'fetchGetListVocabularyInRoom',
  async (data: IDataVocabularyInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchGetListVocabularyInRoom(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddVocabularyInRoom = createAsyncThunk(
  'fetchAddVocabularyInRoom',
  async (data: IFetchVocabInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchAddVocabularyInRoom(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateVocabularyInRoom = createAsyncThunk(
  'fetchUpdateVocabularyInRoom',
  async (data: IUpdateVocabInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchUpdateVocabularyInRoom(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchDeletedVocabularyInRoom = createAsyncThunk(
  'fetchDeletedVocabularyInRoom',
  async (data: IDeletedVocabInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchDeletedVocabularyInRoom(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCheckIsAdminRoomByRoomId = createAsyncThunk(
  'fetchCheckIsAdminRoomByRoomId',
  async (data: IDataVocabularyInRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchCheckIsAdminRoomByRoomId(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSetAllowReadImageInRoom = createAsyncThunk(
  'fetchSetAllowReadImageInRoom',
  async (data: IFetchSetAllowReadImageRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSetAllowReadImageInRoom(data.data);
      //if (res.status === 201) {
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
      //}
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListIpForUpdateByUser = createAsyncThunk(
  'fetchGetListIpForUpdateByUser',
  async (data: IFetchListRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchGetListIpForUpdateByUser(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchChangeStatusSystemIP = createAsyncThunk(
  'fetchChangeStatusSystemIP',
  async (data: IFetchChangeStatusSystemIPAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchChangeStatusSystemIP(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetSystemIP = createAsyncThunk(
  'fetchGetSystemIP',
  async (data: IFetchChangeStatusSystemIPAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchGetSystemIP(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetPublicIPInRoom = createAsyncThunk(
  'fetchGetPublicIPInRoom',
  async (data: IFetchListIPInRoomAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchGetPublicIPInRoom(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdatePublicIPInRoomToSystem = createAsyncThunk(
  'fetchUpdatePublicIPInRoomToSystem',
  async (data: IFetchUpdateIPInRoomIntoSystemDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchUpdatePublicIPInRoomToSystem(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchListUuid = createAsyncThunk(
  'fetchListUuid',
  async (data: IFetchListUuidDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiListUuid(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSendRequestAddDeviceKey = createAsyncThunk(
  'fetchSendRequestAddDeviceKey',
  async (data: IFetchSendRequestAddDeviceKeyAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchSendRequestAddDeviceKey(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAcceptDeviceKey = createAsyncThunk(
  'fetchAcceptDeviceKey',
  async (data: IAcceptDeviceKeyAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAcceptDeviceKey(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAllowedDeviceKey = createAsyncThunk(
  'fetchAllowedDeviceKey',
  async (data: IAllowedDeviceKeyAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAllowedDeviceKey(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAllowDeviceKey = createAsyncThunk(
  'fetchAllowDeviceKey',
  async (data: IAcceptDeviceKeyAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAllowDeviceKey(data?.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSetAllowedPublicIP = createAsyncThunk(
  'fetchSetAllowedPublicIP',
  async (data: IFetchSetAllowedPublicIPDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSetAllowedPublicIP(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchChangeRoleAccount = createAsyncThunk(
  'fetchChangeRoleAccount',
  async (data: IFetchChangeRoleAccountDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchChangeRoleAccount(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchRefreshToken = createAsyncThunk('fetchRefreshToken', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }

  try {
    const res = await apiHelper.fetchRefreshToken(data.params);
    if (res.status === 201) {
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchListApiCallHistory = createAsyncThunk(
  'fetchListApiCallHistory',
  async (data: any) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchListApiCallHistory(data?.params.page, data?.params.dto);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          const result = await data.callBack(res.data);
          return result;
        }
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddKeyLogin = createAsyncThunk(
  'fetchAddKeyLogin',
  async (data: IFetchAddKeyLoginDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchAddKeyLogin(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateRoom = createAsyncThunk(
  'fetchUpdateRoom',
  async (data: IFetchUpdateRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchUpdateRoom(data.data);
      if (res.status === 200) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetRoomRoomById = createAsyncThunk(
  'fetchGetRoomRoomById',
  async (data: IFetchSetAllowReadImageRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchGetRoomRoomById(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCreateDataExample = createAsyncThunk('fetchGetDataExample', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }

  try {
    const res = await apiHelper.fetchCreateDataExample(data.params);
    if (res.status === 201) {
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});
export const fetchAIhelperReviewCode = createAsyncThunk(
  'fetchAIhelperReviewCode',
  async (data: any) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchAIhelperReviewCode(data.params);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListBadWord = createAsyncThunk(
  'fetchGetListBadWord',
  async (data: IFetchGetListBadWordDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchListBadword();

      if (res.status === 200) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListAIHelper = createAsyncThunk('fetchGetListAIHelper', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }

  try {
    const res = await apiHelper.fetchGetListAIHelper(data);

    if (res.status === 200) {
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchSetAllowAiHelperInRoom = createAsyncThunk(
  'fetchSetallowAiHelperInRoom',
  async (data: IFetchSetAllowAiHelperRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSetAllowAiHelperInRoom(data.data);
      //if (res.status === 201) {
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
      //}
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchSetAllowTranslateFileInRoom = createAsyncThunk(
  'fetchSetAllowTranslateFileInRoom',
  async (data: IFetchSetAllowTranlateRoomDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchSetAllowTranSlateInRoom(data.data);
      //if (res.status === 201) {
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
      //}
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchCreateUnitTest = createAsyncThunk('fetchCreateUnitTest', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }

  try {
    const res = await apiHelper.fetchCreateUnitTest(data.params);
    if (res.status === 201) {
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchTranslateFileInRoom = createAsyncThunk(
  'fetchSetAllowTranslateFileInRoom',
  async (data: any) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchTranSlateInRoom(data.data);

      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchTranslateFilePDFInRoom = createAsyncThunk(
  'fetchTranslateFilePDFInRoom',
  async (data: any) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchTranSlatePDFInRoom(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddKeepInRoom = createAsyncThunk(
  'fetchAddKeepInRoom',
  async (data: IFetchAddKeepKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAddKeepKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateKeepInRoom = createAsyncThunk(
  'fetchUpdateKeepInRoom',
  async (data: any) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchUpdateKeepKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListKeepKPT = createAsyncThunk(
  'fetchGetListKeepKPT',
  async (data: IFetchGetListKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListKeepKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListSprintKPT = createAsyncThunk(
  'fetchGetListSprintKPT',
  async (data: any) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListSprintKPT(data.params);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddProblemInRoom = createAsyncThunk(
  'fetchAddProblemInRoom',
  async (data: IFetchAddProblemKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAddProblemKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateProblemInRoom = createAsyncThunk(
  'fetchUpdateProblemInRoom',
  async (data: any) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchUpdateProblemKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListProblemKPT = createAsyncThunk(
  'fetchGetListProblemKPT',
  async (data: IFetchGetListKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListProblemKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddTryInRoom = createAsyncThunk(
  'fetchAddTryInRoom',
  async (data: IFetchAddTryKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAddTryKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateTryInRoom = createAsyncThunk('fetchUpdateTryInRoom', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }
  try {
    const res = await apiHelper.fetchUpdateTryKPT(data.data);
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchGetListTryKPT = createAsyncThunk(
  'fetchGetListTryKPT',
  async (data: IFetchGetListKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListTryKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddSprint = createAsyncThunk(
  'fetchAddSprint',
  async (data: IFetchAddSprintDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiAddSprint(data.data);
      if (res.status === 201) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddCategoriesInRoom = createAsyncThunk(
  'fetchAddCategoriesInRoom',
  async (data: IFetchAddCategoriesKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAddCategoriesKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListCategoriesKPT = createAsyncThunk(
  'fetchGetListCategoriesKPT',
  async (data: IFetchGetListKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListCategoriesKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateKeepCategories = createAsyncThunk(
  'fetchUpdateKeepCategories',
  async (data: IFetchUpdateKeepKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchUpdateKeepCategories(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateProblemCategories = createAsyncThunk(
  'fetchUpdateProblemCategories',
  async (data: IFetchUpdateProblemKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchUpdateProblemCategories(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateTryCategories = createAsyncThunk(
  'fetchUpdateTryCategories',
  async (data: IFetchUpdateTryKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchUpdateTryCategories(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateKeep = createAsyncThunk('fetchUpdateKeep', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }
  try {
    const res = await apiHelper.fetchUpdateKeep(data.data);
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchUpdateTry = createAsyncThunk('fetchUpdateTry', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }
  try {
    const res = await apiHelper.fetchUpdateTry(data.data);
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchDeleteKeep = createAsyncThunk('fetchDeleteKeep', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }
  try {
    const res = await apiHelper.fetchDeleteKeep(data.data);
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchDeleteProblem = createAsyncThunk('fetchDeleteProblem', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }
  try {
    const res = await apiHelper.fetchDeleteProblem(data.data);
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchDeleteTry = createAsyncThunk('fetchDeleteTry', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }
  try {
    const res = await apiHelper.fetchDeleteTry(data.data);
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchUpdateSprint = createAsyncThunk(
  'fetchUpdateSprint',
  async (data: IFetchUpdateSprintDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiUpdateSprint(data.data);
      if (res.status === 200) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateSprintStatus = createAsyncThunk(
  'fetchUpdateSprintStatus',
  async (data: IFetchUpdateSprintStatusDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiUpdateSprintStatus(data.data);
      if (res.status === 200) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);
export const fetchUpdateSprintTimeKPT = createAsyncThunk(
  'fetchUpdateSprintTimeKPT',
  async (data: IFetchUpdateSprintTimeDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }

    try {
      const res = await apiHelper.fetchApiUpdateSprintTime(data.data);
      if (res.status === 200) {
        if (data.callBack) {
          data.callBack(res.data);
          return res.data;
        }
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListKeepKPTByVoted = createAsyncThunk(
  'fetchGetListKeepKPTByVoted',
  async (data: IFetchGetListKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListKeepKPTByVoted(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListProblemKPTByVoted = createAsyncThunk(
  'fetchGetListProblemKPTByVoted',
  async (data: IFetchGetListKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListProblemKPTByVoted(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListTryKPTByVoted = createAsyncThunk(
  'fetchGetListTryKPTByVoted',
  async (data: IFetchGetListKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListTryKPTByVoted(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchVoteKeep = createAsyncThunk('fetchVoteKeep', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }
  try {
    const res = await apiHelper.fetchVoteKeep(data.data);
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchVoteProblem = createAsyncThunk('fetchVoteProblem', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }
  try {
    const res = await apiHelper.fetchVoteProblem(data.data);
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchVoteTry = createAsyncThunk('fetchVoteTry', async (data: any) => {
  if (data.isLoad) {
    store.dispatch(setLoader(true));
  }
  try {
    const res = await apiHelper.fetchVoteTry(data.data);
    if (data.callBack) {
      data.callBack(res.data);
      return res.data;
    }
  } catch (err) {
    if (!(err instanceof Error)) {
      const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
      if (data.callBack) {
        data.callBack(res.data);
      }
      return res.data;
    }
  } finally {
    if (data.isLoad) {
      store.dispatch(setLoader(false));
    }
  }
});

export const fetchGetListCommentKPT = createAsyncThunk(
  'fetchGetListCommentKPT',
  async (data: IFetchCommentAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListCommentKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddCommentKPT = createAsyncThunk(
  'fetchAddCommentKPT',
  async (data: IFetchCommentAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAddComment(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateCommentKPT = createAsyncThunk(
  'fetchUpdateCommentKPT',
  async (data: IFetchCommentAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchUpdateComment(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchDeleteCommentKPT = createAsyncThunk(
  'fetchDeleteCommentKPT',
  async (data: IFetchCommentAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchDeleteComment(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddProblemToTryKPT = createAsyncThunk(
  'fetchAddProblemToTryKPT',
  async (data: IFetchAddProblemKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAddProblemToTry(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListImageKPT = createAsyncThunk(
  'fetchGetListImageKPT',
  async (data: IFetchGetListImageAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchGetListImageKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchAddImageInSprint = createAsyncThunk(
  'fetchAddImageInSprint',
  async (data: IFetcAddImageInSprintAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchAddImageInSprint(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchDeleteImageInSprint = createAsyncThunk(
  'fetchDeleteImageInSprint',
  async (data: IFetcDeleteImageInSprintAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchDeleteImageInSprint(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchDeleteCategoryKPT = createAsyncThunk(
  'fetchDeleteCategoryKPT',
  async (data: IFetchDeleteListKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchDeteteCategories(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdateCategoriesInRoom = createAsyncThunk(
  'fetchUpdateCategoriesInRoom',
  async (data: IFetchUpdateCategoriesKPTAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchUpdateCategoriesKPT(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchGetListPositon = createAsyncThunk(
  'fetchGetListPositon',
  async (data: IFetchListPositionDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchListPosition(data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);

export const fetchUpdatePositionInRoom = createAsyncThunk(
  'fetchUpdatePositionInRoom',
  async (data: IFetchUpdatePositionDataAPI) => {
    if (data.isLoad) {
      store.dispatch(setLoader(true));
    }
    try {
      const res = await apiHelper.fetchUpdateListPosition(data.data);
      if (data.callBack) {
        data.callBack(res.data);
        return res.data;
      }
    } catch (err) {
      if (!(err instanceof Error)) {
        const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
        if (data.callBack) {
          data.callBack(res.data);
        }
        return res.data;
      }
    } finally {
      if (data.isLoad) {
        store.dispatch(setLoader(false));
      }
    }
  },
);
