import React, { useEffect } from 'react'
import { CModal } from '@coreui/react-pro'
import Button from '../button/Button'
import { ICON_X } from 'utils/constants/images'

interface ModalProps {
  title?: string
  content?: any
  visible: boolean
  onClose?: () => void
  size?: 'sm' | 'lg' | 'xl' 
  className?: string
  buttonClose?: string
  buttonOK?: string
  buttonDelete?: string
  handleOK?: () => void
  alignment?: 'top' | 'center'
  hiddenIconX?: boolean
  marginTopContent?: boolean
}

const Modal: React.FC<ModalProps> = ({
  title,
  content,
  visible,
  onClose,
  size,
  className,
  buttonClose,
  buttonOK,
  handleOK,
  alignment,
  buttonDelete,
  hiddenIconX = false,
  marginTopContent,
}) => {
  useEffect(() => {
    if (visible) {
      const initialStyle = document.body.style.overflow;
      const isScroll = initialStyle !== 'hidden';
      if (isScroll) {
        const initialClientWidth = document.body.clientWidth;
        document.body.style.overflow = 'hidden';
        const scrollbarWidth = document.body.clientWidth - initialClientWidth;
        document.body.style.overflow = initialStyle;
        document.documentElement.style.setProperty('--modal-scrollbar-width', scrollbarWidth + "px");
      }
    }
  }, [visible])

  return (
    <CModal
      visible={visible}
      onClose={onClose}
      className={`components__modal ${className}`}
      size={size}
      alignment={alignment}
      backdrop="static"
    >
      {title && (
        <div className="components__modal-header d-flex justify-content-center">
          <span className="components__modal-title">{title}</span>
        </div>
      )}
      {
        !hiddenIconX && <div className="components__modal-icon-only">
          <img src={ICON_X} alt="" onClick={onClose} />
        </div>
      }

      <div className={`components__modal-content ${marginTopContent ? '' : 'mt-3'}`}>{content}</div>
      {buttonClose && (
        <div className="components__modal-footer d-flex justify-content-center">
          {buttonOK && <Button className="btn-primary " label={buttonOK} onClick={handleOK} />}
          {buttonDelete && (
            <Button className="btn-delete" label={buttonDelete} onClick={handleOK} />
          )}
          <Button className="btn-secondary" label={buttonClose} onClick={onClose} />
        </div>
      )}
    </CModal>
  )
}

export default Modal
