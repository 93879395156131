import { axios } from 'utils/plugins';
import { apiRoutes } from '.';

export const fetchApiLogin = async (data: ILoginDataAPI) => {
  const result = await axios.post(apiRoutes.LOGIN, data);
  return result;
};

export const fetchVerifyOTP = async (data: IVerifyOTPDataAPI) => {
  const result = await axios.post(apiRoutes.VERIFY_OTP, data);
  return result;
};

export const fetchVerify2fa = async (data: IVerify2faDataAPI) => {
  const result = await axios.post(apiRoutes.VERIFY_2FA, data);
  return result;
};

export const fetchApiChangePassword = async (data: IDataChangePasswordAPI) => {
  const result = await axios.put(`${apiRoutes.CHANGE_PASSWORD}`, data);
  return result;
};

export const fetchApiSendEmailForgotPassword = async (data: IDataForgotPasswordEmailAPI) => {
  const result = await axios.post(`${apiRoutes.SENT_EMAIL_FORGOT_PASSPORT}`, data);
  return result;
};

export const fetchApiListAccount = async (params: any) => {
  const result = await axios.get(apiRoutes.LIST_ACCOUNT, { params: params });
  return result;
};
export const fetchApiResetPassword = async (data: IResetPasswordDataAPI) => {
  const result = await axios.post(`${apiRoutes.RESET_PASSWORD}`, data);
  return result;
};

export const fetchApiAddAccount = async (data: IAddAccountDataAPI) => {
  const result = await axios.post(apiRoutes.ADD_ACCOUNT, data);
  return result;
};
export const fetchCreateRoom = async (data: ICreateRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.ROOM}`, data);
  return result;
};

export const fetchUpdateRoom = async (data: IUpdateRoomDataAPI) => {
  const result = await axios.put(`${apiRoutes.ROOM}`, data);
  return result;
};

export const fetchGetListRoom = async (params: any) => {
  const result = await axios.get(`${apiRoutes.ROOM}`, { params: params });
  return result;
};

export const fetchUpdateMemberInRoom = async (data: IUpdateMemberRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.UPDATE_MEMBER_OF_ROOM}`, data);
  return result;
};

export const fetchApiCheckSetPasswordKey = async (data: ICheckSetPasswordKeyDataAPI) => {
  const result = await axios.post(`${apiRoutes.CHECK_SET_PASSWORDKEY}`, data);
  return result;
};

export const fetchGetListRoomByAccount = async (params: any) => {
  const result = await axios.get(`${apiRoutes.LIST_ROOM_BY_ACCOUNT}`, { params: params });
  return result;
};

export const fetchApiCreateTokenRoom = async (data: ICreateTokenRoomAPI) => {
  const result = await axios.post(apiRoutes.CREATE_TOKEN_ROOM, data);
  return result;
};

export const fetchCheckUserExistInRoom = async (data: ICheckUserExistInRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.CHECK_USER_EXIST_IN_ROOM}`, data);
  return result;
};

export const fetchDataChatInRoom = async (params: any) => {
  const result = await axios.get(apiRoutes.MESSAGE_BY_ROOM, { params: params });
  return result;
};

export const fetchTranslateImage = async (data: IFetchTranslateImageDataAPI) => {
  const result = await axios.post(apiRoutes.TRANSLATE_IMAGE, data);
  return result;
};

export const fetchListLanguage = async (params: any) => {
  const result = await axios.get(apiRoutes.LANGUAGE, { params: params });
  return result;
};

export const fetchCheckPublicIpAllowed = async (params: any) => {
  const result = await axios.get(apiRoutes.CHECK_PUBLIC_IP_ALLOWED, {
    params: { publicIpAddress: params },
  });
  return result;
};

export const fetchGetPublicIpAddress = async () => {
  const result = await axios.get(`https://api.ipify.org?format=json`);
  return result;
};

export const fetchSubcriptionToServer = async (data: any) => {
  const result = await axios.post(apiRoutes.SUBSCRIPTION, data);
  return result;
};

export const fetchSetActive = async (data: ISetActiveAPI) => {
  const result = await axios.post(apiRoutes.SET_ACTIVE, data);
  return result;
};

export const fetchSetReAuthen = async (data: ISetReAuthenAPI) => {
  const result = await axios.post(apiRoutes.SET_RE_AUTHEN, data);
  return result;
};

export const fetchSetBockLogin = async (data: ISetBlockLoginAPI) => {
  const result = await axios.post(apiRoutes.SET_BLOCK_LOGIN, data);
  return result;
};

export const fetchListAccountForUpdateToRoom = async (params: any) => {
  const result = await axios.get(apiRoutes.LIST_ACCOUNT_FOR_UPDATE_TO_ROOM, { params: params });
  return result;
};

export const fetchGetListPublicIpByUser = async (params: any) => {
  const result = await axios.get(`${apiRoutes.GET_LIST_PUBLIC_IP_BY_ACCOUNT}`, {
    params: params,
  });
  return result;
};

export const fetchUpdatePublicIPInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ADD_OR_REMOVE_IP}`, data);
  return result;
};

export const fetchAddNewPublicIPInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ADD_NEW_PUBLIC_IP}`, data);
  return result;
};

export const fetchListIdAdminInRoom = async (data: any) => {
  const result = await axios.get(`${apiRoutes.GET_LIST_ID_ADMIN_IN_ROOM}`, data);
  return result;
};

export const fetchAddAdminToRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ADD_ID_ADMIN_IN_ROOM}`, data);
  return result;
};

export const fetchCheckIsAdminRoom = async () => {
  const result = await axios.get(`${apiRoutes.CHECK_ADMIN_ROOM}`);
  return result;
};

export const fetchListPubLicIP = async (params: any) => {
  const result = await axios.get(apiRoutes.FIND_ALL_PUBLIC_IP, { params: params });
  return result;
};

export const fetchSetAllowed = async (data: any) => {
  const result = await axios.post(apiRoutes.SET_ALLOWED_PUBLIC_IP, data);
  return result;
};

export const fetchAddNewPublicIP = async (data: any) => {
  const result = await axios.post(apiRoutes.ADD_NEW_PUBLIC_IP_ADRESS, data);
  return result;
};

export const fetchApiMain = async () => {
  const result = await axios.get(`${apiRoutes.API_MAIN_HELLO}`);
  return result;
};

export const fetchSetBlockOTP = async (data: ISetBlockOTPAPI) => {
  const result = await axios.post(apiRoutes.SET_BLOCK_OTP, data);
  return result;
};
export const fechSuggestMessage = async (data: IFetchISuggestMessageAPIAPI) => {
  const result = await axios.post(apiRoutes.SUGGEST_MESSAGE, data);
  return result;
};

export const fetchCheckIpInRoom = async (params: any) => {
  const result = await axios.get(apiRoutes.CHECK_IP_IN_ROOM, { params: params });
  return result;
};
export const fetchGetProfile = async (params: any) => {
  const result = await axios.get(apiRoutes.GET_PROFILE, { params: params });
  return result;
};

export const fetchUpdateProfile = async (params: any) => {
  const result = await axios.post(apiRoutes.UPDATE_PROFILE, params);
  return result;
};

export const fetchGetListVocabularyInRoom = async (params: any) => {
  const result = await axios.get(`${apiRoutes.VOCABULARY_IN_ROOM}`, {
    params: params,
  });
  return result;
};

export const fetchAddVocabularyInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.VOCABULARY_IN_ROOM}`, data);
  return result;
};

export const fetchUpdateVocabularyInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.VOCABULARY_UPDATE}`, data);
  return result;
};

export const fetchDeletedVocabularyInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.VOCABULARY_DELETED}`, data);
  return result;
};

export const fetchCheckIsAdminRoomByRoomId = async (params: any) => {
  const result = await axios.get(`${apiRoutes.CHECK_ADMIN_ROOM_BY_ID}`, {
    params: params,
  });
  return result;
};

export const fetchSetAllowReadImageInRoom = async (data: ISetAllowReadImageRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.SET_ALLOW_READ_IMAGE}`, data);
  return result;
};

export const fetchSetAllowAiHelperInRoom = async (data: ISetAllowAiHelperRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.ALLOW_AI_HELPER}`, data);
  return result;
};

export const fetchSetAllowTranSlateInRoom = async (data: ISetAllowTranlateRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.ALLOW_TRANSLATE_FILE}`, data);
  return result;
};

export const fetchTranSlateInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.TRANSLATE_FILE_EXCEL}`, data);
  return result;
};

export const fetchTranSlatePDFInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.TRANSLATE_FILE_PDF}`, data);
  return result;
};

export const fetchGetListIpForUpdateByUser = async (params: any) => {
  const result = await axios.get(`${apiRoutes.GET_LIST_IP_FOR_UPDATE}`, {
    params: params,
  });
  return result;
};

export const fetchChangeStatusSystemIP = async (data: any) => {
  const result = await axios.post(apiRoutes.CHANGE_STATUS_SYSTEM_IP, data);
  return result;
};

export const fetchGetSystemIP = async (params: any) => {
  const result = await axios.get(`${apiRoutes.SYSTEM_IP}`, {
    params: params,
  });
  return result;
};

export const fetchGetPublicIPInRoom = async (params: any) => {
  const result = await axios.get(`${apiRoutes.GET_LIST_IP_IN_ROOM}`, {
    params: params,
  });
  return result;
};

export const fetchUpdatePublicIPInRoomToSystem = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ADD_OR_REMOVE_IP_IN_SYSTEM}`, data);
  return result;
};

export const fetchApiListUuid = async (params: any) => {
  const result = await axios.get(apiRoutes.DEVICE_ADDRESS, { params: params });
  return result;
};

export const fetchSendRequestAddDeviceKey = async (data: any) => {
  const result = await axios.post(`${apiRoutes.SEND_DEVICE_ADDRESS}`, data);
  return result;
};

export const fetchAcceptDeviceKey = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ACCEPT_DEVICE_ADDRESS}`, data);
  return result;
};

export const fetchAllowedDeviceKey = async (data: any) => {
  const result = await axios.post(`${apiRoutes.SET_STATUS_DEVICE_ADDRESS}`, data);
  return result;
};

export const fetchAllowDeviceKey = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ALLOW_DEVICE_ADDRESS}`, data);
  return result;
};

export const fetchSetAllowedPublicIP = async (data: any) => {
  const result = await axios.post(`${apiRoutes.SET_ALLOWED_LIST_PUBLIC_IP}`, data);
  return result;
};

export const fetchChangeRoleAccount = async (data: IChangeRoleAccountDataAPI) => {
  const result = await axios.post(apiRoutes.UPDATE_ROLE, data);
  return result;
};

export const fetchRefreshToken = async (data: any) => {
  const result = await axios.post(apiRoutes.REFRESH_TOKEN, data);
  return result;
};

export const fetchListApiCallHistory = async (page: number, dto: any) => {
  const result = await axios.get(apiRoutes.API_CALL_HISTORY, { params: { page, dto } });
  return result;
};

export const fetchAddKeyLogin = async (data: IAddKeyLoginDataAPI) => {
  const result = await axios.post(apiRoutes.KEY_LOGIN, data);
  return result;
};

export const fetchGetRoomRoomById = async (data: ISetAllowReadImageRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.GET_ROOM_BY_ID}`, data);
  return result;
};

export const fetchCreateDataExample = async (data: any) => {
  const result = await axios.post(`${apiRoutes.CREATE_DATA_EXAMPLE}`, data);
  return result;
};
export const fetchAIhelperReviewCode = async (data: any) => {
  const result = await axios.post(apiRoutes.AI_HELPER_REVIEW_CODE, data);
  return result;
};
export const fetchListBadword = async () => {
  const result = await axios.get(`${apiRoutes.BAD_WORD}`);
  return result;
};

export const fetchGetListAIHelper = async (data: any) => {
  const result = await axios.get(`${apiRoutes.AI_HELPER}`, data);
  return result;
};

export const fetchCreateUnitTest = async (data: any) => {
  const result = await axios.post(`${apiRoutes.AI_HELPER_UNIT_TEST}`, data);
  return result;
};

export const fetchAddKeepKPT = async (data: IAddKeepKPTDataAPI) => {
  const result = await axios.post(`${apiRoutes.KEEP_KPT}`, data);
  return result;
};

export const fetchUpdateKeepKPT = async (data: any) => {
  const result = await axios.put(`${apiRoutes.KEEP_KPT}`, data);
  return result;
};

export const fetchGetListKeepKPT = async (data: IGetListKPTDataAPI) => {
  const result = await axios.get(`${apiRoutes.KEEP_KPT}`, {
    params: data,
  });
  return result;
};

export const fetchAddProblemKPT = async (data: IAddProblemKPTDataAPI) => {
  const result = await axios.post(`${apiRoutes.PROBLEM_KPT}`, data);
  return result;
};

export const fetchUpdateProblemKPT = async (data: any) => {
  const result = await axios.put(`${apiRoutes.PROBLEM_KPT}`, data);
  return result;
};

export const fetchGetListProblemKPT = async (data: IGetListKPTDataAPI) => {
  const result = await axios.get(`${apiRoutes.PROBLEM_KPT}`, {
    params: data,
  });
  return result;
};

export const fetchAddTryKPT = async (data: IAddTryKPTDataAPI) => {
  const result = await axios.post(`${apiRoutes.TRY_KPT}`, data);
  return result;
};

export const fetchUpdateTryKPT = async (data: any) => {
  const result = await axios.put(`${apiRoutes.TRY_KPT}`, data);
  return result;
};

export const fetchGetListTryKPT = async (data: IGetListKPTDataAPI) => {
  const result = await axios.get(`${apiRoutes.TRY_KPT}`, {
    params: data,
  });
  return result;
};

export const fetchGetListSprintKPT = async (params: any) => {
  const result = await axios.get(`${apiRoutes.SPRINT_KPT}`, {
    params: params,
  });
  return result;
};

export const fetchApiAddSprint = async (data: IAddSprintDataAPI) => {
  const result = await axios.post(apiRoutes.SPRINT_KPT, data);
  return result;
};

export const fetchAddCategoriesKPT = async (data: IAddCategoriesKPTDataAPI) => {
  const result = await axios.post(`${apiRoutes.CATEGORIES_KPT}`, data);
  return result;
};

export const fetchGetListCategoriesKPT = async (data: IGetListKPTDataAPI) => {
  const result = await axios.get(`${apiRoutes.CATEGORIES_KPT}`, {
    params: data,
  });
  return result;
};

export const fetchUpdateKeepCategories = async (data: IUpdateKeepKPTDataAPI) => {
  const result = await axios.put(`${apiRoutes.KEEP_CATEGORIES}`, data);
  return result;
};

export const fetchUpdateProblemCategories = async (data: IUpdateProblemKPTDataAPI) => {
  const result = await axios.put(`${apiRoutes.PROBLEM_CATEGORIES}`, data);
  return result;
};

export const fetchUpdateTryCategories = async (data: IUpdateTryKPTDataAPI) => {
  const result = await axios.put(`${apiRoutes.TRY_CATEGORIES}`, data);
  return result;
};

export const fetchUpdateKeep = async (data: any) => {
  const result = await axios.put(`${apiRoutes.KEEP_KPT}`, data);
  return result;
};

export const fetchUpdateTry = async (data: any) => {
  const result = await axios.put(`${apiRoutes.TRY_KPT}`, data);
  return result;
};

export const fetchDeleteKeep = async (data: any) => {
  const result = await axios.post(`${apiRoutes.DELETE_KEEP_KPT}`, data);
  return result;
};

export const fetchDeleteProblem = async (data: any) => {
  const result = await axios.post(`${apiRoutes.DELETE_PROBLEM_KPT}`, data);
  return result;
};

export const fetchDeleteTry = async (data: any) => {
  const result = await axios.post(`${apiRoutes.DELETE_TRY_KPT}`, data);
  return result;
};

export const fetchApiUpdateSprint = async (data: IUpdateSprintDataAPI) => {
  const result = await axios.put(apiRoutes.SPRINT_KPT, data);
  return result;
};

export const fetchApiUpdateSprintStatus = async (data: IUpdateSprintStatusDataAPI) => {
  const result = await axios.put(apiRoutes.SPRINT_KPT_STATUS, data);
  return result;
};

export const fetchApiUpdateSprintTime = async (data: IUpdateSprintTimeDataAPI) => {
  const result = await axios.put(apiRoutes.SPRINT_KPT_TIME, data);
  return result;
};

export const fetchGetListKeepKPTByVoted = async (data: IGetListKPTDataAPI) => {
  const result = await axios.get(`${apiRoutes.KEEP_KPT_BY_VOTED}`, {
    params: data,
  });
  return result;
};

export const fetchGetListProblemKPTByVoted = async (data: IGetListKPTDataAPI) => {
  const result = await axios.get(`${apiRoutes.PROBLEM_KPT_BY_VOTED}`, {
    params: data,
  });
  return result;
};

export const fetchGetListTryKPTByVoted = async (data: IGetListKPTDataAPI) => {
  const result = await axios.get(`${apiRoutes.TRY_KPT_BY_VOTED}`, {
    params: data,
  });
  return result;
};

export const fetchVoteKeep = async (data: any) => {
  const result = await axios.post(`${apiRoutes.VOTE_KEEP_KPT}`, data);
  return result;
};

export const fetchVoteProblem = async (data: any) => {
  const result = await axios.post(`${apiRoutes.VOTE_PROBLEM_KPT}`, data);
  return result;
};

export const fetchVoteTry = async (data: any) => {
  const result = await axios.post(`${apiRoutes.VOTE_TRY_KPT}`, data);
  return result;
};

export const fetchGetListCommentKPT = async (data: ICommentDataAPI) => {
  const result = await axios.get(`${apiRoutes.COMMENT_KPT}`, {
    params: data,
  });
  return result;
};

export const fetchAddComment = async (data: any) => {
  const result = await axios.post(`${apiRoutes.COMMENT_KPT}`, data);
  return result;
};

export const fetchUpdateComment = async (data: any) => {
  const result = await axios.put(`${apiRoutes.COMMENT_KPT}`, data);
  return result;
};

export const fetchDeleteComment = async (data: any) => {
  const result = await axios.post(`${apiRoutes.DELETE_COMMENT_KPT}`, data);
  return result;
};

export const fetchAddProblemToTry = async (data: IAddProblemToTryDataAPI) => {
  const result = await axios.put(`${apiRoutes.ADD_PROBLEM_TO_TRY}`, data);
  return result;
};

export const fetchGetListImageKPT = async (data: IGetListKPTDataAPI) => {
  const result = await axios.get(`${apiRoutes.KPT_IMAGE}`, {
    params: data,
  });
  return result;
};

export const fetchAddImageInSprint = async (data: IAddImageInSprintAPI) => {
  const result = await axios.post(`${apiRoutes.KPT_IMAGE}`, data);
  return result;
};

export const fetchDeleteImageInSprint = async (data: IDeleteImageInSprintAPI) => {
  const result = await axios.post(`${apiRoutes.DELETE_KPT_IMAGE}`, data);
  return result;
};

export const fetchDeteteCategories = async (data: IDeleteListKPTDataAPI) => {
  const result = await axios.post(`${apiRoutes.DELETE_CATEGORIES_KPT}`, data);
  return result;
};

export const fetchUpdateCategoriesKPT = async (data: IUpdateCategoriesKPTDataAPI) => {
  const result = await axios.put(`${apiRoutes.CATEGORIES_KPT}`, data);
  return result;
};

export const fetchListPosition = async (data: IFetchListPositionDataAPI) => {
  const result = await axios.get(`${apiRoutes.USER_POSITION_KPT}`, {
    params: data.params,
  });
  return result;
};

export const fetchUpdateListPosition = async (data: IUpdatePositionDataAPI) => {
  const result = await axios.put(`${apiRoutes.USER_POSITION_KPT}`, data);
  return result;
};
