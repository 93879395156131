export const enum LANGUAGE {
  JAPAN = 'jp',
  ENGLISH = 'en',
  VIETNAM = 'vn',
}

export const usernameRegex = /@(\w+)/;

export const enum ROLE_ACCOUNT {
  ADMIN = 'super_admin',
  ADMIN_ROOM = 'admin_room',
  USER = 'user',
}

export const enum KPT_TYPE {
  KEEP = 'KEEP',
  PROBLEM = 'PROBLEM',
  TRY = 'TRY',
  IMAGE = 'IMAGE',
}
